import React, { useMemo, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { FigmaIcon } from 'components';
import getSortFunction from './getSortFunction';
import Row from './Row';
const managerNames = {
	default: [
		'n',
		'name',
		'visitTime',
		'visitSeconds',
		'togetherVisits',
		'pdz',
		'sum',
		'tasks',
		'photo',
		'pointCode'
	],
	byDates: [
		'date',
		'status',
		'visits',
		'workingDayCount',
		'togetherVisits',
		'workingSeconds',
		'ordersCount',
		'onPoint',
		'notOnPoint',
		'sum',
		'tasks',
		'photo',
	],
	byPoints: [
		'n',
		'name',
		'visits',
		'togetherVisits',
		'visitSeconds',
		'ordersCount',
		'onPoint',
		'notOnPoint',
		'sum',
		'tasks',
		'photo',
		'pointCode',
	]
},
	groupNames = {
		default: [
			'n',
			'name',
			'dn',
			'visits',
			'togetherVisits',
			'workingTime',
			'ordersCount',
			'onPoint',
			'notOnPoint',
			'sum',
			'pdz',
			'tasks',
			'photo',
		],
		summary: [
			'n',
			'name',
			'dn',
			'clients',
			'visits',
			'togetherVisits',
			'workingDayCount',
			'workingTimeAvg',
			'ordersPointCount',
			'ordersCount',
			'onPoint',
			'notOnPoint',
			'sum',
			'tasks',
			'photo',
			'clientCount'
		]
	},
	managerTitles = {
		n: '#',
		name: 'Наименование',
		dn: 'DN',
		clients: 'Клиенты',
		visits: 'Визиты',
		togetherVisits: 'СВ',
		visitTime: 'Начало визита',
		visitSeconds: 'Время визита',
		workingSeconds: 'Раб. время',
		pdz: 'ПДЗ',
		sum: 'Сумма',
		tasks: 'Задачи',
		photo: '',
		pointCode: '',
		ordersPointCount: 'ТП, кол-во',
		onPoint: 'ВТ',
		notOnPoint: 'НТ',
		status: 'Статус',
		date: 'Дата',
		ordersCount: 'Заказы',
		workingDayCount: 'РД'
	},
	managerTitlesHover = {
		...managerTitles,
		togetherVisits: 'Совместные визиты',
		onPoint: 'В точке',
		notOnPoint: 'Не в точке',
		ordersPointCount: 'Количество заказов торгового представителя, всего',
		pdz: 'Просроченная дебиторская задолженность',
		sum: 'Сумма заказов',
		ordersCount: 'Заказы ВТ/НТ',
		workingSeconds: 'Рабочее время',
		photo: 'Фото',
		workingDayCount: 'Рабочий день'
	},
	managerTitlesOverrides = {
		byPoints: {
			sum: 'Итого'
		},
		byDates: {
			workingSeconds: 'Раб. время'
		}
	},
	groupTitles = {
		...managerTitles,
		name: 'Менеджер',
		workingDayCount: 'Кол-во дней',
		workingTime: 'Раб. время',
		workingTimeAvg: 'Ср. время',
		sum: 'Сумма',
		managerID: '',
	},
	groupTitlesHover = {
		...groupTitles,
		...managerTitlesHover,
		workingDayCount: 'Количество дней',
		workingTimeAvg: 'Среднее время визита',
	},
	groupTitlesOverrides = {
		summary: {
			workingTimeAvg: 'Ср. время',
		},
	},
	groupTitlesOverridesHover = {
		summary: {
			workingTimeAvg: 'Среднее время визита',
			onPoint: 'Сумма в точке'
		},
	},
	colWidth = {
		n: 50,
		date: 113,
		name: 280,
		dn: 80,
		clients: 140,
		visits: 140,
		togetherVisits: 100,
		visitTime: 85,
		visitSeconds: 90,
		workingSeconds: 90,
		workingTime: 110,
		workingTimeAvg: 110,
		workingDayCount: 100,
		ordersPointCount: 60,
		ordersCount: 80,
		sum: 110,
		pdz: 100,
		tasks: 100,
		photo: 70,
		pointCode: 55,
		onPoint: 110,
		notOnPoint: 130
	},
	groupColWidthOverrides = {
		visits: 140
	},
	useStyles = makeStyles(theme => ({
		root: {
			overflow: 'auto',
			flex: 1
		},
		table: {
			tableLayout: 'fixed',
			minWidth: 972,
			width: 'auto'
		},
		head: {
			padding: 12,
			paddingRight: 0,
			overflow: 'hidden',
			left: 'unset'
		},
		title: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			flexShrink: 1,
			alignItems: 'center'
		},
		icon: {
			display: 'block'
		},
		nowrap: {
			whiteSpace: 'nowrap'
		},
		sortLabel: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			overflow: 'hidden',
			'&:hover': {
				color: theme.palette.primary.main
			},
			'& .MuiTableSortLabel-icon': {
				margin: [[0, 3]]
			}
		},
		activeSortLabel: {
			color: [theme.palette.primary.main, '!important']
		},
		left: {
			justifyContent: 'flex-start'
		}
	})),

	isText = {
		name: true,
		visitTime: true
	},
	orderByOverrides = {
		tasks: ['tasks', 'closed'],
		visits: ['visits', 'visited', 'nearby']
	},

	iconStyle = { color: '#22B0E0', fontSize: 10 },
	IconComponent = props => <FigmaIcon name="chevronDown" {...props} style={iconStyle} />;


export default React.memo(TableTable);

const formatDateSort = (dateString) => {
	return dateString ? dateString.split('.').reverse().join('') : '';
}

const formatDateAddDots = (dateString) => {
	if (dateString) {
		const yearString = dateString.substr(0, 4);
		const monthString = dateString.substr(4, 2);
		const dayString = dateString.substr(6, 2);

		return dayString + '.' + monthString + '.' + yearString;
	}
}

function TableTable({ isRange, isGroup, tableType = 'default', rows = [], managerId, groupId, pointsWaiter }) {
	const classes = useStyles(),
		cellWidth = useMemo(
			() => calcPercent(
				isGroup ? { ...colWidth, ...groupColWidthOverrides } : colWidth,
				1024
			),
			[isGroup]
		),
		names = isGroup ? (groupNames[tableType] || groupNames.default) : (managerNames[tableType] || managerNames.default),
		titles = useMemo(
			() => isGroup ? { ...groupTitles, ...groupTitlesOverrides[tableType] } : { ...managerTitles, ...managerTitlesOverrides[tableType] },
			[isGroup, tableType]
		),
		titlesHover = useMemo(
			() => isGroup ? { ...groupTitlesHover, ...groupTitlesOverridesHover[tableType] } : { ...managerTitlesHover, ...managerTitlesOverrides[tableType] },
			[isGroup, tableType]
		),
		[asc, setAsc] = useState(true),
		[orderBy, setOrderBy] = useState(tableType === 'byDates' ? 'date' : 'n');



	useEffect(
		() => {
			setAsc(true);
			setOrderBy(tableType === 'byDates' ? 'date' : 'n');
		},
		[managerId, groupId, tableType]
	);

	return (
		<div className={classes.root}>
			<Table stickyHeader className={classes.table}>
				<TableHead>
					<TableRow>
						{names.map(name =>
							<TableCell
								key={name}
								style={{ width: cellWidth[name] }}
								className={clsx(classes.head, { [classes.nowrap]: name !== 'name' })}
							>
								{name === 'managerID' || name === 'pointCode' ?
									titles[name]
									:
									<TableSortLabel
										className={clsx(classes.sortLabel, {
											[classes.activeSortLabel]: orderBy === name,
											[classes.left]: name === 'name' || name === 'date'
										})}
										active={orderBy === name}
										direction={asc ? 'asc' : 'desc'}
										onClick={() => {
											if (name === orderBy) setAsc(!asc);
											else setOrderBy(name);
										}}
										{...{ IconComponent }}
									>
										<div className={classes.title} title={titlesHover[name]}>
											{name === 'photo' ?
												<FigmaIcon className={classes.icon} name="camera" />
												:
												titles[name]
											}
										</div>
									</TableSortLabel>
								}
							</TableCell>
						)}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows[0] &&
						<Row
							isHead
							row={rows[0]}
							{...{ isGroup, names, managerId, groupId, pointsWaiter }}
						/>
					}
					{rows.slice(1)
					.map((row, n) => ({ ...row, n }))
					.map(row => ({...row, date: formatDateSort(row.date)}))
					.sort(getSortFunction(orderByOverrides[orderBy] || orderBy, asc, isText[orderBy]))
					.map(row => ({...row, date: formatDateAddDots(row.date)}))
					.map(row => (
						<Row
							key={(isGroup ? row.managerID : row.pointCode) || row.n}
							{...{ isRange, isGroup, row, names, managerId, groupId, pointsWaiter }}
						/>
					))}
				</TableBody>
			</Table>
		</div>
	);
}


function calcPercent(obj, minWidth) {
	return Object.keys(obj).reduce(
		(res, key) => ({ ...res, [key]: `${Math.ceil(obj[key] / minWidth * 100)}%` }),
		{}
	);
}
