import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles ( theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'scroll',
        padding: [[5]]
    },
    visit: {
        border: '1px solid #E3E3E3',
        marginBottom: '5px'
    },
    visitRow: {
        display: 'flex'
    },
    visitCell: {
        flex: 1,
        border: '1px solid #E3E3E3',
        padding: [[5]],
        color: '#030303',
        fontSize: '12px'
    },
    right: {
        textAlign: 'right'
    },
    center: {
        textAlign: 'center'
    }

}) );

const formatDate = (date) => {
    if (date) {
        const dateString = date.split('T')[0];
        const timeString = date.split('T')[1];

        const dateArray = dateString.split('-');
        const newDateArray = dateArray.reverse();
        const newDateString = newDateArray.join('-')+'T'+timeString;

        date = newDateString;

        return date
            .replace(/-/g, '.')
            .replace(/T/g, ' ')
            .replace(/.000000Z/g, '');
    } else {
        return ''
    }
}

export default function TogetherVisits ({ points = [] })
{
    const classes = useStyles();
    
    return (
        <div className={ classes.root }>
            { points.map ( ( { users = [], visit_begin_datetime, full_name }, i  ) =>

            <div
                key={ i }
                className={ classes.visit }
            >
                <div className={ classes.visitRow }>
                    <div className={ classes.visitCell }>
                        { formatDate(visit_begin_datetime) }
                    </div>
                    <div className={ classes.visitCell + ' ' + classes.right }>
                        { full_name }
                    </div>
                </div>

                { users.map ( ( { full_name }, i ) =>
                    <div
                        key={ i }
                        className={ classes.visitRow }
                    >
                        <div className={ classes.visitCell + ' ' + classes.center }>
                            { full_name }
                        </div>
                    </div>
                )}
            </div>
            )}
        </div>
    );
}