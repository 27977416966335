import React, { useMemo, useCallback } from 'react';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { FigmaIcon, OrdersCountBadge } from 'components';
import { getColorById, formatPrice } from 'functions';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'actions';
const orderTypes = [ 'onPoint', 'notOnPoint' ],
	orderColors = {
		onPoint: 'green',
		notOnPoint: 'yellow',
	},
	orderTitles = {
		onPoint: 'В точке',
		notOnPoint: 'Не в точке',
	};


export default React.memo ( Header );

function Header ({ isGroup, tableType, id, name, count, description, workingTime, visits: { visited, all, nearby } = {}, orders, groupId, clientCount, clients, dn, isRange })
{
	const classes = useStyles(),
		dispatch = useDispatch(),
		theme = useTheme(),
		style = useMemo (
			() => ({ backgroundColor: getColorById ( id ) }),
			[ id ]
		),
		backButton = useSelector ( st => st.table.backButton ),
		goBack = useCallback (
			() => dispatch (
				actions.tableRequest ({ ...backButton })
			),
			[ backButton, dispatch ]
		),
		hasOrders = !!orders && typeof orders === 'object';

	return (
		<div className={ classes.root }>
			<div className={ clsx ( classes.row, {
				[ classes.spaceBetween ]: !isGroup
			} ) }>
				{ !!backButton &&
					<IconButton className={ classes.backButton } color="primary" size="small" onClick={ goBack }>
						<FigmaIcon className={ classes.backArrow } name="backArrow" />
					</IconButton>
				}
				{ name &&
					<div className={ classes.col }>
						<div className={ classes.row }>
							<Typography className={ classes.title }>{ name }</Typography>
							{ isGroup ?
								<Typography className={ clsx ( classes.visits, classes.marginLeft, classes.nowrap ) }>
									Всего менеджеров: <b>{ count }</b>
								</Typography>
								:
								<React.Fragment>
									<div className={ classes.circle } {...{ style }} />
									{ description &&
										<div className={ classes.description }>
											<Typography>{ description }</Typography>
										</div>
									}
								</React.Fragment>
							}
						</div>
						{ !isGroup && !isRange &&
							<div className={ classes.row2 }>
								<Typography className={ classes.visits }>
									Всего визитов:
									&nbsp;
									<b className={ classes.nowrap }>
										<b className={ classes.visited }>{ nearby }</b>/{ visited } из <b>{ all }</b>
									</b>
								</Typography>
								<Typography className={ classes.marginLeft }>
									DN:
									&nbsp;
									<b className={ classes.nowrap }>
										<b>{ dn }</b>
									</b>
								</Typography>
								{ !!workingTime &&
									<Typography className={ clsx ( classes.visits, classes.marginLeft ) }>
										Рабочее время: <b className={ classes.nowrap }>{ workingTime }</b>
									</Typography>
								}
							</div>
						}
						{ !isGroup && isRange &&
						<div className={ classes.row2 }>
							<Typography className={ classes.visits }>
								Всего клиентов:
								&nbsp;
								<b className={ classes.green }>{ ( clients || {} ).nearby || 0 }</b>
								/
								<b>{ ( clients || {} ).visited || 0 }</b>
								/
								<b className={ classes.nowrap }>
									<b>{ ( clients || {} ).all || 0 }</b>
								</b>
							</Typography>
							<Typography className={ classes.marginLeft }>
								DN:
								&nbsp;
								<b className={ classes.nowrap }>
									<b>{ dn }</b>
								</b>
							</Typography>
							{ !!workingTime &&
							<Typography className={ clsx ( classes.visits, classes.marginLeft ) }>
								Рабочее время: <b className={ classes.nowrap }>{ workingTime }</b>
							</Typography>
							}
						</div>
						}
					</div>
				}
				{ hasOrders &&
					<div className={ classes.orders }>
						{ orderTypes.map ( type =>
							<div key={ type } className={ classes.order }>
								<OrdersCountBadge
									className={ classes.orderCount }
									orderType={ type }
									count={ orders[ type ].count }
								/>
								<Typography className={ classes.nowrap }>
									{ orderTitles[ type ] }:&nbsp;
									<b style={{ color: theme.palette.common[ orderColors[ type ] ] }}>{ formatPrice ( orders[ type ].sum ) }</b>
									<span className={ classes.grey }>&nbsp;₽</span>
								</Typography>
							</div>
						) }
					</div>
				}
			</div>
		</div>
	);
}


const useStyles = makeStyles (theme => ({
	root: {
		padding: [[ 16, 16, 16, 0 ]],
		minHeight: 60
	},
	row: {
		display: 'flex',
		alignItems: 'flex-end',
		overflow: 'hidden',
		position: 'relative'
	},
	row2: {
		display: 'flex',
		alignItems: 'flex-end',
		overflow: 'hidden',
		marginTop: 6
	},
	backButton: {
		position: 'absolute',
		top: 4
	},
	backArrow: {
		fontSize: 16
	},
	col: {
		marginLeft: 26
	},
	circle: {
		width: 14,
		height: 14,
		borderRadius: 7,
		backgroundColor: 'red',
		alignSelf: 'center',
		flexShrink: 0,
		marginRight: 8,
		marginLeft: 6,
		marginTop: 2
	},
	title: {
		fontSize: 25,
		fontWeight: 'bold',
		lineHeight: 1.1
	},
	visits: {
		lineHeight: 1.4
	},
	green: {
		color: theme.palette.common.green
	},
	marginLeft: {
		marginLeft: 24
	},
	visited: {
		color: '#129C8B'
	},
	description: {
		padding: [[ 2, 6 ]],
		borderRadius: 5,
		backgroundColor: '#F2F2F2'
	},
	spaceBetween: {
		justifyContent: 'space-between',
		flexWrap: 'wrap'
	},
	grey: {
		color: '#B6B6B6'
	},
	orders: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		alignSelf: 'center',
		flexWrap: 'wrap'
	},
	order: {
		display: 'flex',
		alignItems: 'center',
		minHeight: 34
	},
	orderCount: {
		marginRight: 6,
		marginLeft: 24
	},
	nowrap: {
		whiteSpace: 'nowrap'
	},
}) );
