import React, { useState, useEffect } from 'react';
import { Typography, Tabs, Tab, makeStyles } from '@material-ui/core';
import { Waiter, Tasks, Orders, Photos, Refresh, Merch, TogetherVisits } from 'components';
import { SideButton } from 'mlk-ui-kit';
import clsx from 'clsx';
import api from 'api';
const tabs = [ 'ПДЗ/Заказы', 'Задачи', 'Визиты', 'Мерч', 'Фото'],
	requests = [ 'getOrders', 'getTasks', 'getTogetherVisits', 'getMerches', 'getPhoto' ],
	useStyles = makeStyles ( theme => ({
		root: {
			display: 'flex',
			flexDirection: 'column',
			position: 'absolute',
			top: 0,
			right: 0,
			bottom: 0,
			width: 430,
			backgroundColor: '#F8F8F8',
			zIndex: 2
		},
		headerBlock: {
			margin: [[ 18, 18, 0, 18 ]]
		},
		header: {
			display: 'flex',
			alignItems: 'center',
			marginBottom: 7,
			marginRight: 24
		},
		manager: {
			marginRight: 12
		},
		red: {
			color: '#E51A1A'
		},
		grey: {
			color: '#A8A8A8'
		},
		green: {
			color: '#129C8B'
		},
		button: {
			position: 'absolute',
			top: 0,
			right: 0
		},
		icon: {
			fontSize: 10,
			color: 'white'
		},
		table: {
			width: '100%'
		},
		th: {
			whiteSpace: 'nowrap',
			color: '#A8A8A8',
			textAlign: 'left',
			verticalAlign: 'top',
			fontWeight: 'normal',
			paddingRight: 8
		},
		tab: {
			borderBottom: '1px solid #E3E3E3',
			minWidth: 8,
			fontWeight: 'bold',
			color: '#000',
			fontSize: 11,
			padding: 0
		},
		tabMerch: {
			borderBottom: '1px solid #E3E3E3',
			minWidth: 90,
			fontWeight: 'bold',
			color: '#000',
			fontSize: 11,
			padding: 3,
		},
		waiter: {
			flex: 1,
			position: 'static'
		}
	}) );


export default function Panel ({ open, id, manager, managerID, closed, opened, name, address = '-', date, range, closePanel })
{
	const classes = useStyles(),
		[ tab, setTab ] = useState ( 0 ),
		[ data, setData ] = useState ( {} ),
		{ waiter, error, tasks, pdz, statement, orders, photo, merches, points } = data,
		request = () => {
			setData ({ waiter: true });
			api.web[ requests[ tab ] ]( id, managerID, date, range )
				.then (
					res => setData ({ ...res, waiter: false })
				);
		};

	useEffect (
		request,
		[ tab, id, managerID, date, range ]
	);

	return (
		<div className={ classes.root }>
			<SideButton
				className={ classes.button }
				onClick={ closePanel }
			/>
			<div className={ classes.headerBlock }>
				<div className={ classes.header }>
					<Typography className={ classes.manager }><b>{ manager }</b></Typography>
					{
						!Array.isArray(range) &&
						<b>
						<span
							className={ clsx ( classes.red, {
								[ classes.green ]: closed === opened
							} ) }
						>
							{ closed || 0 }
						</span>
							<span className={ classes.grey }>/</span>
							<span className={ classes.green }>{ opened || 0 }</span>
						</b>
					}
				</div>
				<table className={ classes.table }>
					<tbody>
						<tr>
							<th className={ classes.th }>Название:</th>
							<td>{ name }</td>
						</tr>
						<tr>
							<th className={ classes.th }>Адрес:</th>
							<td>{ address }</td>
						</tr>
					</tbody>
				</table>
			</div>
			<Tabs
				value={ tab }
				onChange={ ( e, tab ) => setTab ( tab ) }
				variant="fullWidth"
				textColor="primary"
				indicatorColor="primary"
			>
				{ tabs.map ( ( label, i ) =>
					<Tab
						key={ i }
						className={ i===2 ? classes.tabMerch : classes.tab }
						label={ <Typography><b>{ label }</b></Typography> }
					/>
				) }
			</Tabs>
			{ !( error || waiter ) &&
				<React.Fragment>
					{ tab === 0 &&
						<Orders {...{ pdz, statement, orders }} />
					}
					{ tab === 1 &&
						<Tasks {...{ tasks }} />
					}
					{ tab === 2 &&
						<TogetherVisits {...{ points }} />
					}
					{ tab === 3 &&
						<Merch {...merches} />
					}
					{ tab === 4 &&
						<Photos {...{ photo, pointID: id, managerID, byTasksDate: date, byTasksRange: range }} />
					}
				</React.Fragment>
			}
			{ !!error && !waiter &&
				<Refresh onClick={ request } {...{ error }} />
			}
			<Waiter className={ classes.waiter } show={ waiter } />
		</div>
	);
}
